





















































































































































































































































































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";
import ImageInput from "@/components/ImageInput.vue";

export default Vue.extend({
  components: { ImageInput },
  data() {
    let headers = [
      { text: "Trạng thái", value: "status" },
      { text: "Mã phiếu", value: "id" },
      { text: "Mã đơn", value: "package_ids" },
      { text: "Tên kinh doanh", value: "sale_user_id" },
      { text: "Tên khách hàng", value: "customer_id" },
      { text: "Người lập phiếu", value: "accountant_user_id" },
      { text: "Xác nhận thanh lý", value: "approve_user_id" },
      { text: "Trên xe", value: "truck_ids" },
      { text: "Tiền vận chuyển", value: "fee_transport" },
      { text: "Phí UTNK", value: "fee_trust" },
      { text: "Phí VCVN", value: "fee_vn" },
      { text: "Lợi nhuận", value: "profit" },
      { text: "Báo dư", value: "surplusage" },
      { text: "Tổng tiền", value: "final_money" },
      { text: "Đã thanh toán", value: "paid" },
      { text: "Chiết khấu", value: "discount" },
      { text: "Còn nợ", value: "total_debt" },
      { text: "Ghi chú", value: "note" },
      { text: "Ngày thanh lý", value: "approve_time" },
      { text: "Đã giao", value: "delivered" },
      { text: this.$t("IMAGE"), value: "images" },
      { text: "Số GD", value: "so_gds", sortable: false },
      !userManager.getImpersonateUserInfo() && {
        text: this.$t("ACTIONS"),
        value: "actions",
        sortable: false,
      },
    ];

    if (userManager.checkRole(["kvn"], true)) {
      headers = [
        { text: "Trạng thái", value: "status" },
        { text: "Mã phiếu", value: "id" },
        { text: "Mã đơn", value: "package_ids" },
        { text: "Tên kinh doanh", value: "sale_user_id" },
        { text: "Tên khách hàng", value: "customer_id" },
        { text: "Người lập phiếu", value: "accountant_user_id" },
        { text: "Xác nhận thanh lý", value: "approve_user_id" },
        { text: "Trên xe", value: "truck_id" },
        { text: "Đã giao", value: "delivered" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ];
    }

    return {
      userManager,
      dialog: false,
      form: false,
      dialogDelete: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers,
      search: "",
      filterPackageId: "",
      filterTruckId: 0,
      filterStatus: "",
      filterSoGds: "",
      options: {},
      loading: false,
      data: [],
      dataCount: 0,
      editedIndex: -1,
      confirmMode: "",
      editedItem: {},
      defaultItem: {},
      availableStatuses: [
        {
          value: "",
          text: "Tất cả",
        },
        {
          value: "dtl",
          text: "Đã thanh lý",
        },
        {
          value: "ctl",
          text: "Chưa thanh lý",
        },
      ],
      urlFunc(item) {
        return Vue.filter("liquidationSlipImageUrl")(item);
      },
    };
  },
  computed: {
    formTitle() {
      return this.$t("EDIT");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      const q: any = {
        options: this.options,
        filters: [],
        extra: {},
      };
      if (this.search && this.search.length > 2) {
        q.filters.push({
          key: "id",
          operator: "=",
          value: parseInt(this.search.substring(2)),
        });
      }
      if (this.filterPackageId) {
        q.extra.packageId = this.filterPackageId;
      }
      if (this.filterTruckId) {
        q.filters.push({
          key: "truck_id",
          operator: "=",
          value: this.filterTruckId,
        });
      }
      if (this.filterStatus) {
        switch (this.filterStatus) {
          case "dtl":
            q.filters.push({
              key: "status",
              operator: "=",
              value: "Approved",
            });
            break;
          case "ctl":
            q.filters.push({
              key: "status",
              operator: "!=",
              value: "Approved",
            });
            break;
        }
      }
      if (this.filterSoGds) {
        q.filters.push({
          key: "so_gds",
          operator: "match",
          value: this.filterSoGds,
        });
      }

      this.loading = true;
      const { items, count } = await apiClient.liquidationSlipGetAll(q);
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.confirmMode = "";
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      if (!(await apiClient.liquidationSlipDelete(this.editedItem))) {
        return;
      }
      await this.initialize();
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedItem.so_gds && this.editedItem.so_gds.length) {
        const existed = await apiClient.liquidationSlipCheckSoGds(
          this.editedItem.id,
          this.editedItem.so_gds.join(",")
        );
        if (existed) {
          const confirmed = confirm("Số GD trùng. Bạn có muốn tiếp tục?");
          if (!confirmed) {
            return;
          }
        }
      }

      let fields = undefined;
      if (userManager.checkRole("kd", true)) {
        fields = ["images"];
      }
      const result = await apiClient.liquidationSlipUpdate(
        this.editedItem,
        fields
      );
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    cancelApprove(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.confirmMode = "cancelApprove";
      this.dialogDelete = true;
    },
    async cancelApproveConfirm() {
      if (!(await apiClient.liquidationSlipCancelApprove(this.editedItem))) {
        return;
      }
      this.closeDelete();
      await this.initialize();
    },
    async transfer(item) {
      const result = await apiClient.liquidationSlipUpdate({
        id: item.id,
        delivered: true,
        delivered_time: Math.round(new Date().getTime() / 1000),
      });
      if (result) {
        await this.initialize();
      }
    },
    truckIds(packages) {
      const ids = [];
      for (const pkg of packages) {
        if (ids.indexOf(pkg.truck_id) === -1) {
          ids.push(pkg.truck_id);
        }
      }
      return ids;
    },
    async exportAll() {
      const url = apiClient.liquidationSlipExport();
      location.replace(url);
    },
    async exportByTruck(truckId) {
      const url = apiClient.liquidationSlipExport(null, truckId);
      location.replace(url);
    },
  },
});
